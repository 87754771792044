<script>
import {
    layoutComputed
} from "@/state/helpers";
import Vertical from "./vertical";

export default {
    components: {
        Vertical,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    methods: {},
    mounted() {
        // document.querySelector("html").setAttribute('dir', 'rtl');
        var langSelect = document.querySelector(".lang-dropdown")
        if (this.$i18n.locale == "ar") {
            document.querySelector("html").setAttribute('dir', 'rtl');
            langSelect.style.margin = "0 0 0 50px"
        }
        else {
            document.querySelector("html").setAttribute('dir', 'ltr');
            langSelect.style.margin = "0 50px 0 0"
        }
    }
};
</script>

<template>
<div>
    <Vertical  layout="vertical">
        <slot />
    </Vertical>

</div>
</template>
