<script>

import PageHeader from "@/components/page-header";
import Layouts from "../../layouts/main";

export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    Layouts,
    PageHeader,
  },
  data() {
    return {
      accounts: [],
      matchedAccounts: [],
    };
  },
  methods: {
    addAccount(account) {
      this.http
          .post("subledgers/accounts-setup/add-subledger-sub-account", {
            account_id: account?.account_id,
            name: account.name,
            name_en: account?.name_en,
            level_three_id: account?.search.id
          })
          .then((res) => {
            if (res.status) {
              account.search = "";
              this.getLevelThree();
            }
          });
    },
    getLevelThree() {
      this.http
          .post(
              "subledgers/accounts-setup/pagination",
              {
                subledger_id: 1
              }
          )
          .then((res) => {
            this.accounts = res.data;
          });
    },
    searchAccount(account,search) {
      this.http
          .post("subledgers/accounts-setup/search", {
            search,
            level:account.level,
            type:account.type,
          })
          .then((res) => {
            if (res.status) {
              this.matchedAccounts = res.data
            }
          });
    },
    editLevelThree(account) {
      this.http
          .post("subledgers/types/setup/edit-level-three", {
            id: account.sas_id,
            level_three_id:account.search.id
          })
          .then((res) => {
            if (res.status) {
              delete account.search
              this.getLevelThree();
            }
          });
    },
    deleteAccount(index, sub_index, sub_account) {
      if (this.accounts[index]?.sub_accounts[sub_index]?.sas_id) {
        console.log(sub_account);
        var data = {
          title: "popups.delete",
          msg: "popups.deletemsg",
        };
        this.popup.confirm(data).then((res) => {
          if (res) {
            this.http.delete("subledgers/accounts-setup/subledger-sub-account", sub_account.sas_id).then(() => {
              this.getLevelThree();
            });
          }
        });
        return;
      }
      else {
        this.accounts[index]?.sub_accounts.splice(sub_index, 1);
      }
    },
    showLabel(one) {
      return this.$i18n.locale == "ar" ? one.code + '-' + one.name : one.code + '-' + one.name_en;
    },
    showEditInput(account) {
      account.isEdit = true;
    },
    hideEditInput(account) {
      account.isEdit = false;
    },
    updateSubAccountName(account) {
      console.log(account);
      account.isEdit = false;
      this.http
          .put("subledgers/accounts-setup/subledger-sub-account", account.sas_id, {
            name: account?.sas_name,
            name_en: account?.sas_name_en,
          })
          .then((res) => {
            if (res.status) {
              this.getLevelThree();
            }
          });
    },
  },
  computed: {

  },
  created() {
    this.getLevelThree(1);
  },
};
</script>

<template>

  <Layouts>
    <PageHeader :title="$t('accounts_setup.setup') + '  -  '+ $t('menu.menuitems.subledgers_setup.subItems.customers_subledger')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table
                  class="table table-centered table-nowrap table-striped table-hover align-middle"
              >
                <thead>
                <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("accounts_setup.module") }}</th>
                  <th scope="col">
                    {{ $t("accounts_setup.sub_accounts") }}
                  </th>
                  <!-- <th scope="col">
                    {{ $t("accounts_setup.selected_account") }}
                  </th>
                   -->
                  <th scope="col">{{ $t("accounts_setup.operations") }}</th>
                </tr>
                </thead>
                <tbody style="vertical-align: top">
                <tr v-for="(account, index) in accounts" :key="account">
                  <td class="pt-4">{{ index + 1 }}</td>
                  <td class="pt-4">
                    {{
                      $i18n.locale == "ar"
                          ? account.account_name
                          : account.account_name_en
                    }}
                  </td>
                  <td class="pt-4">
                    <div class="table-responsive">
                      <table
                          class="table table-centered table-nowrap align-middle"
                      >
                        <thead>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(
                                sub_account, sub_index
                              ) in account?.sub_accounts"
                            :key="sub_account"
                        >
                          <td :colspan="sub_account?.sas_id ? 1 : 3" style="padding: 0 !important;">
                            <div
                                @dblclick="showEditInput(sub_account)"
                                v-if="sub_account?.sas_id"
                                style="cursor: pointer;width:100%;height:100%;padding: 0.75rem 0.75rem "
                            >
                              <div v-if="sub_account.isEdit">
                                <div
                                    type="button"
                                    @click="hideEditInput(sub_account)"
                                >
                                  <i
                                      class="text-danger float-end"
                                      style="
                                          font-weight: bold;
                                          font-size: medium;
                                        "
                                  >X</i
                                  >
                                </div>
                                <div>
                                  <input
                                      type="text"
                                      v-model="sub_account.sas_name"
                                      @change="
                                          updateSubAccountName(sub_account)
                                        "
                                      class="form-control block"
                                  />
                                  <input
                                      type="text"
                                      v-model="sub_account.sas_name_en"
                                      @change="
                                          updateSubAccountName(sub_account)
                                        "
                                      class="form-control"
                                  />
                                </div>
                              </div>
                              <div v-else>
                                {{
                                  $i18n.locale == "ar"
                                      ? sub_account?.sas_name
                                      : sub_account?.sas_name_en
                                }}
                              </div>
                            </div>
                            <div v-else>
                              <input
                                  v-model="sub_account.name"
                                  type="text"
                                  :placeholder="$t('popups.name_ar')"
                                  class="form-control block"
                              />
                              <input
                                  v-model="sub_account.name_en"
                                  type="text"
                                  :placeholder="$t('popups.name_en')"
                                  class="form-control"
                              />
                            </div>
                          </td>
                          <td v-if="sub_account?.sas_id">{{ sub_account?.code }}</td>
                          <td v-if="sub_account?.sas_id">
                            {{
                              $i18n.locale == "ar"
                                  ? sub_account?.l3_name
                                  : sub_account?.l3_name_en
                            }}
                          </td>
                          <td>
                            <AutoComplete
                                v-model="sub_account.search"
                                :suggestions="matchedAccounts"
                                @complete="searchAccount(account,$event.query)"
                                :optionLabel="showLabel"
                                style="
                                    color: #333 !important;
                                    border-radius: 10px !important;
                                  "
                            />
                            <button
                                v-if="sub_account?.sas_id"
                                style="margin-inline-start: 8px"
                                @click="editLevelThree(sub_account)"
                                type="button"
                                class="btn btn-primary"
                            >
                              {{ $t("popups.edit") }}
                            </button>
                            <button
                                v-else
                                style="margin-inline-start: 8px"
                                @click="addAccount(sub_account)"
                                type="button"
                                class="btn btn-success"
                            >
                              {{ $t("popups.add") }}
                            </button>
                            <button v-if="account.sub_accounts.length>1"
                                    style="margin-inline-start: 8px"
                                    @click="
                                    deleteAccount(index, sub_index, sub_account)
                                  "
                                    type="button"
                                    class="btn btn-danger"
                            >
                              <i class="bx bx-trash"></i>
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                  <td>
                    <button
                        v-if="account.has_sub"
                        type="button"
                        @click="
                          account?.sub_accounts.push({
                            account_id: account?.account_id,
                          })
                        "
                        class="btn btn-light float-end mb-4"
                    >
                        <span
                            class="bx bxs-plus-circle float-end fa-2x"
                            style="color: #2a3042 !important"
                        ></span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- end  table -->
            <!-- Button trigger modal -->

            <!--   Apps  Pagination     -->
            <ul
                class="pagination pagination-rounded justify-content-center mb-2"
            >
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a
                    class="page-link"
                    href="javascript: void(0);"
                    @click="getLevelThree(page - 1)"
                    aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li
                  class="page-item"
                  :class="{ active: p == page }"
                  v-for="p in tot_pages"
                  :key="p"
              >
                <a
                    class="page-link"
                    href="javascript: void(0);"
                    @click="getLevelThree(p)"
                >{{ p }}</a
                >
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a
                    class="page-link"
                    href="javascript: void(0);"
                    @click="getLevelThree(page + 1)"
                    aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
            <!--end-->
          </div>
        </div>
      </div>
    </div>
  </Layouts>
</template>
