<script>
// import axios from "axios";

import { authComputed } from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      lang: "en",
      text: null,
      flag: null,
      value: null,
      sideNavHide: false,
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
      if (this.sideNavHide == false && this.$i18n.locale == "ar") {
        this.sideNavHide = true;
        console.log(this.sideNavHide);
      } else if (this.sideNavHide == true && this.$i18n.locale == "ar") {
        console.log(this.$i18n.locale);
        this.sideNavHide = false;
      } else if (this.sideNavHide == false && this.$i18n.locale == "en") {
        this.sideNavHide = true;
        console.log(this.sideNavHide);
      } else if (this.sideNavHide == true && this.$i18n.locale == "en") {
        console.log(this.$i18n.locale);
        this.sideNavHide = false;
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

    logoutUser() {
      // eslint-disable-next-line no-unused-vars
      localStorage.removeItem("currentUser");
      // this.$router.push({
      //   name: "default",
      // });
      window.location.reload();
      // axios.get("http://127.0.0.1:8000/api/logout").then((res) => {
      //   this.$router.push({
      //     name: "default",
      //   });
      // });
    },
  },
  computed: {
    ...authComputed,
  },
};
</script>
<script setup>
import { watch } from "vue";
import { useI18n } from "vue-i18n";
const i18n = useI18n();
watch(
  () => i18n.locale.value,
  (data) => {
    localStorage.lang = data;
    if (localStorage.lang == "ar") {
      document.querySelector("html").setAttribute("dir", "rtl");
      document.querySelector("html").setAttribute("lang", "ar");
    } else {
      document.querySelector("html").setAttribute("dir", "ltr");
      document.querySelector("html").setAttribute("lang", "en");
    }
  }
);
</script>
<template>
  <header id="page-topbar">
    <div class="navbar-header" style="padding: 0">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <!-- <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="17" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-light.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="19" />
            </span>
          </router-link> -->
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
      </div>

      <div class="d-flex" style="margin-inline-end: 1rem">
        <select
          v-model="$i18n.locale"
          style="color: #2a3042"
          class="lang-dropdown border-0 m-0"
        >
          <option
            v-for="(locale, i) in languages"
            :key="`locale-${i}`"
            :value="locale.language"
          >
            {{
              locale.title ? locale.title : lang == "en" ? "English" : "Arabic"
            }}
          </option>
        </select>
        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="logoutUser()"
          >
            <i class="bx bx-log-in text-danger fa-2xl"></i>
            <!-- <i class="bx bx-log-in-circle" style="color: red"></i> -->
          </button>
        </div>
      </div>
    </div>
  </header>
</template>
