import { createStore } from 'vuex'

import modules from './modules'

const store = createStore({
  state:{
    currentUser: (localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : ""),
    token: null,
    // baseUrl: "http://104.248.238.177:5008/api/",
    // baseUrl: "http://localhost:5008/api/",
    baseUrl: "https://sayedmustafa.com/api/",
    filesUrl: "https://sayedmustafa.com/",
    autoLogin: false,
  },
  mutations:{
    printElement(_, elementId) {
  var originalElement = document.getElementById(elementId);
  var clonedElement = originalElement.cloneNode(true);

  // Clone the stylesheets and inline styles from <head>
  var stylesheets = document.querySelectorAll('link[rel="stylesheet"]');
  var inlineStyles = document.querySelectorAll("style");

  var printWindow = window.open("", "_blank");
  var printDocument = printWindow.document;

  var printHead = printDocument.head;
  var printBody = printDocument.body;

  // Copy attributes from the original <html> element to the new window's <html> element

  printDocument.documentElement.setAttribute(
      "dir",
      document.documentElement.dir
  );

  // Copy stylesheets to the new window
  stylesheets.forEach(function (sheet) {
    var link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = sheet.href;
    printHead.appendChild(link);
  });

  // Copy inline styles to the new window
  inlineStyles.forEach(function (style) {
    var clonedStyle = style.cloneNode(true);
    printHead.appendChild(clonedStyle);
  });

  // Append cloned content to the new window
  printBody.appendChild(clonedElement);

  // Add print-specific CSS
  var printStyle = document.createElement("style");
  printStyle.textContent = `
      @media print {
        table {
       overflow-y: visible ;

        }
        
      }
    `;
  printHead.appendChild(printStyle);

  // Print the new window
  printWindow.print();
}
  },
  actions:{
    printElement({commit},elementId){
      commit("printElement",elementId)
    }
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store

