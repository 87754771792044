import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import BootstrapVue3 from "bootstrap-vue-3";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from "vue3-scroll-spy";
import jQuery from "jquery";
import VueSweetalert2 from "vue-sweetalert2";
import responseAlert from "./services/responseAlert";
import CurrencyInput from "@/components/CurrencyInput";
import Maska from "maska";
import { i18n } from "./i18n.js";
import popups from "./services/popups";
import http from "./services/http";
import $e from "./services/$e";
import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import login from "./services/Login";
import loading from "./services/loading";



import "sweetalert2/dist/sweetalert2.min.css";
import "@vueform/slider/themes/default.css";
import store from "./state/store";

import "../src/design/app.scss";
import processCurrency from "@/services/processCurrency";
import moment from "@/services/moment";

var app = createApp(App)
  .use(store)
  .use(router)
  .use(require("vue-chartist"))
  .use(BootstrapVue3)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(VueSweetalert2)
  .use(responseAlert)
  .use(i18n)
  .use(registerScrollSpy)
  .use(Maska)
  .use(popups)
  .use(moment)
  .use(http)
  .use($e)
  .use(PrimeVue)
  .use(jQuery)
  .use(loading)
  .use(login)
  .use(processCurrency);

app.component("AutoComplete", AutoComplete);
app.component("CurrencyInput", CurrencyInput);
app.mount("#app");

