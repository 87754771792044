class TreeBuilder{
    constructor(element_selector,options) {
        this.zoom = 100

        this.element = document.querySelector(element_selector)
        this.element.style.border = "solid grey thick"
        this.element.style.borderRadius = "7px"
        this.element.style.overflow = "auto"
        if(this.element){
            this.element.innerHTML = `
<div class="svg-control-bar">
    <div class="svg-control-inner">
    <button class="btn btn-success export-button" style="margin-right: 10px;">Export</button>
    <button class="btn btn-light zoom-out">-</button>
    <span class="zoom-value">100%</span>
    <button class="btn btn-light zoom-in">+</button>
    <button class="btn btn-success add-root-button">Add Root</button>
    
</div>
</div>
<div class="svg-container">
<svg id="diagramSvg" xmlns="http://www.w3.org/2000/svg">
      <style id="stylesheet">
      .svg-container{
          width: 100%;
          height:calc(100vh - 150px);
    overflow: auto;
    padding:10px
        }
 
      .svg-control-inner{
      width:100%;
      height:100%;
      position:absolute;
      left:0;
      top:0;
  position: relative;
    display: flex;
    justify-content: center;
    align-items: center;    
      }
      .add-root-button{
      margin-left:10px
      }
      .zoom-value{
      font-weight:bold;
      font-size:120%;
      padding:0 5px
      } 
      .svg-control-bar{
      position:relative;
            display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 5px;
    box-shadow: 0 1px 10px grey;
    margin-bottom: 15px;
    height:50px
      }
      text {
}

rect {
  stroke: black;
  opacity: 0.5;
  fill:rgb(24,36,95);
  stroke-width: 1.5;
}

line {
  stroke: black;
  opacity: 0.5;
  stroke-width: 1.5;
}

g .delete-button{
       background: rgb(255, 117, 117);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    transform: translate(0, -59%);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: none;
    box-shadow: 0 0 3px black;
       }
       g .add-button,g .root-btn{
          background: rgb(61,255,53);
    /*width: 16px;*/
    height: 16px;
    /*border-radius: 50%;*/
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -2px);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #111111;
    border: none;
    box-shadow: 0 0 3px black;
       }
       g .root-btn{
       transform: unset;
       bottom: unset;       
       }
       g .delete-button:hover{
       box-shadow: 0 0 1px black;
       }

</style>
      <defs>
        <marker id="arrowHead" viewBox="-10 -5 10 10"
                markerUnits="strokeWidth" markerWidth="6" markerHeight="5"
                orient="auto">
          <path d="M -10 -5 L 0 0 L -10 5 z"></path>
        </marker>
      </defs>
      <g></g>
          </svg>
</div>
`
            this.maxLevels = options.maxLevels ? options.maxLevels : 3
            this.diagramSvg = this.element.querySelector("svg")
            this.diagramGroup = this.element.querySelector("g")
            this.width = this.diagramSvg.getClientRects()[0].width
            this.height = this.diagramSvg.getClientRects()[0].height
            let mouseX,mouseY
            this.element.querySelector(".add-root-button").addEventListener("click",()=>{
                console.log("hello world")
                this._data.push({
                    inputs:this.inputs,
                    buttons:this.buttons,
                })
                this.build()
            })
            this.element.querySelector(".export-button").addEventListener("click",()=>{
                this.save()
            })
            this.diagramSvg.addEventListener("mousedown",(event)=>{
                 console.log("event",event)
                mouseX = event.offsetX
                mouseY = event.offsetY
                this.svgFocusTimeout = setTimeout(()=>{
                    this.diagramSvg.style.cursor = "grabbing"
                    this.svgFocused = true
                },100)
            })
            this.diagramSvg.addEventListener("mouseup",(event)=>{
                console.log(event)
                this.diagramSvg.style.cursor = "unset"
                this.svgFocused = false
                clearTimeout(this.svgFocusTimeout)
            })
            this.diagramSvg.addEventListener("mousemove",(event)=>{
                if(this.svgFocused){
                    this.diagramSvg.parentElement.scrollLeft += mouseX - event.offsetX
                    this.diagramSvg.parentElement.scrollTop += mouseY - event.offsetY
                }
            })
            this.element.querySelector(".svg-control-bar .zoom-out")
                .addEventListener("click",()=>{
                    this.zoom-=1
                    var scrollLeftPercentage = this.diagramSvg.parentElement.scrollLeft/this.diagramSvg.parentElement.scrollWidth
                    this.element.querySelector(".zoom-value").innerHTML = this.zoom+"%"
                    this.diagramSvg.style.height = (this.diagramSvg.getClientRects()[0].height-0.01*this.height)+"px"
                    this.diagramSvg.style.width = (this.diagramSvg.getClientRects()[0].width-0.01*this.width)+"px"
                    this.diagramGroup.style.transform = `scale(${this.zoom/100})`
                    this.diagramSvg.parentElement.scrollLeft = scrollLeftPercentage*this.diagramSvg.parentElement.scrollWidth
                })
            this.element.querySelector(".svg-control-bar .zoom-in")
                .addEventListener("click",()=>{
                    this.zoom+=1
                    var scrollLeftPercentage = this.diagramSvg.parentElement.scrollLeft/this.diagramSvg.parentElement.scrollWidth
                    this.element.querySelector(".zoom-value").innerHTML = this.zoom+"%"
                    this.diagramSvg.style.height = (this.diagramSvg.getClientRects()[0].height+0.01*this.height)+"px"
                    this.diagramSvg.style.width = (this.diagramSvg.getClientRects()[0].width+0.01*this.width)+"px"
                    this.diagramGroup.style.transform = `scale(${this.zoom/100})`
                    this.diagramSvg.parentElement.scrollLeft = scrollLeftPercentage*this.diagramSvg.parentElement.scrollWidth
                })
            this.options = options
            this._data = options.data
            this.buttons = options.buttons
            this.inputs = options.inputs
            this.movements = []
            document.addEventListener('keyup', event=> {
                console.log("this.movements",this.movements,this.inputFocused)
                if ( !this.inputFocused && this.movements.length > 0 && event.ctrlKey && event.key === 'z') {
                    this._data =  this.movements.pop()
                    this.build()
                }
            });
            this.build()
        }
        else{
            throw new Error("Element is not existed")
        }

    }
    build(){
        while (this.diagramGroup.childNodes.length > 0)
            this.diagramGroup.removeChild(this.diagramGroup.childNodes[0]);

        this.createParents()
        this.makeLevels()
        console.log(this._data)
        // Now render the tree.
        this.diagramSvg.getElementById("arrowHead").setAttribute(
            "markerHeight", 5);

        this.diagramSvg.style.width =  "100%";

        // Add visual elements.
        var namespace = "http://www.w3.org/2000/svg";
        // yPos
        for(let outerLevelIdx = 0; outerLevelIdx < this.outerLevels.length; outerLevelIdx++) {
            let levels = this.outerLevels[outerLevelIdx]
            for (let levelIdx = 0; levelIdx < levels.length; levelIdx++) {
                let level = levels[levelIdx];
                for (let memberIdx = 0; memberIdx != level.length; memberIdx++) {
                    let group = level[memberIdx];
                    for (let nodeIdx = 0; nodeIdx != group.length; nodeIdx++) {
                        var node = group[nodeIdx];
                        var rect = document.createElementNS(namespace, "rect");
                        rect.setAttributeNS(null, "fill", node.parent ? node.parent.color : "lightblue")
                        node.rect = rect
                        this.diagramGroup.appendChild(rect);
                        if (levelIdx > 0) {
                            console.log("node.parent.rect.getBBox()", node.parent.rect.getBBox())
                            var yValue = node.parent.rect.getBBox().y + node.parent.rect.getBBox().height + 70
                            console.log(yValue)
                        } else
                            yValue = 0
                        node.y = yValue
                        // create inputs
                        var foreignObject = document.createElementNS(namespace, "foreignObject");
                        node.foreignObject = foreignObject
                        this.diagramGroup.appendChild(foreignObject);
                        foreignObject.style.position = "relative"
                        foreignObject.style.paddingRight = "10px"
                        var div = document.createElement("div")
                        div.style.width = "fit-content"
                        div.style.minWidth = "100px"
                        div.style.marginLeft = "10px"
                        div.style.marginTop = "10px"
                        div.style.blockSize = "fit-content"

                        // create delete button
                        if(!node.newNode && this.getLevel(node)<this.maxLevels) {
                            let addButton = document.createElement("button")

                            var addTmp = node => {
                                addButton.addEventListener("click", () => {
                                    if (!node.children)
                                        node.children = []
                                    var inputs = this.inputs.map(item => Object.assign({}, item))
                                    var buttons = this.buttons.map(item => Object.assign({}, item))
                                    node.children.push({
                                        is_editable: true,
                                        newNode: true,
                                        inputs,
                                        buttons
                                    })
                                    console.log("addedToNode",node)
                                    this.scrollLeft = this.diagramSvg.parentElement.scrollLeft
                                    this.scrollTop = this.diagramSvg.parentElement.scrollTop
                                    this.build()
                                })
                            }
                            addTmp(node)
                            addButton.classList.add("add-button")
                            addButton.innerHTML = "+"
                            div.appendChild(addButton)
                        }
                        else if(node.newNode){
                            let saveButton = document.createElement("button")
                            var saveTmp = node => {
                                saveButton.addEventListener("click", () => {
                                   if(this.options.onSave){
                                       this.options.onSave({
                                           level:this.getLevel(node),
                                           id:node.parent.id,
                                           inputs:node.inputs.map(input=>{
                                               return {
                                                   placeholder:input.placeholder,
                                                   value:input.value,
                                               }
                                           })
                                       }, (id) => {
                                           delete node.newNode
                                           node.id = id
                                           this.scrollLeft = this.diagramSvg.parentElement.scrollLeft
                                           this.scrollTop = this.diagramSvg.parentElement.scrollTop
                                           this.build()
                                       })
                                   }
                                })
                            }
                            saveTmp(node)
                            saveButton.classList.add("add-button")
                            saveButton.innerHTML = "Save"
                            div.appendChild(saveButton)
                        }
                        if(node.is_editable) {
                            let deleteButton = document.createElement("button")
                            var deleteTmp = node => {
                                deleteButton.addEventListener("click", () => {
                                    if (node.newNode) {
                                        node.parent.children.splice(node.parent.children.findIndex(i => i.treeStamp == node.treeStamp), 1)
                                        this.scrollLeft = this.diagramSvg.parentElement.scrollLeft
                                        this.scrollTop = this.diagramSvg.parentElement.scrollTop
                                        this.build()
                                    } else if (this.options.onDelete) {
                                        node.level = this.getLevel(node)
                                        this.options.onDelete(node, () => {
                                            node.parent.children.splice(node.parent.children.findIndex(i => i.treeStamp == node.treeStamp), 1)
                                            this.scrollLeft = this.diagramSvg.parentElement.scrollLeft
                                            this.scrollTop = this.diagramSvg.parentElement.scrollTop
                                            this.build()
                                        })
                                    }
                                })
                            }
                            deleteTmp(node)
                            deleteButton.innerHTML = "x"
                            deleteButton.classList.add("delete-button")
                            div.appendChild(deleteButton)
                        }

                        foreignObject.appendChild(div)
                        node.inputs.forEach(input => {
                            input.element = document.createElement("input")
                            div.appendChild(input.element)
                            input.element.value = input.value ? input.value : ""
                            input.element.class = input.class
                            input.element.disabled = node.is_editable?"":"disabled"
                            input.element.style.width = "100%"
                            input.element.style.minWidth = "100%"
                            if(input.value){
                                var textWidth = this.getTextWidth(input.value)
                                if (input.element.getBoundingClientRect().width < textWidth)
                                    input.element.style.width = (textWidth + 10) + "px"
                            }
                            input.element.style.display = "block"
                            input.element.style.margin = "auto"
                            input.element.placeholder = input.placeholder
                            let listeners = node=>{
                                input.element.addEventListener("focus", () => {
                                    this.inputFocused = true
                                })
                                input.element.addEventListener("blur", () => {
                                    this.inputFocused = false
                                })
                                input.element.addEventListener("change", () => {
                                    input.value = input.element.value
                                    console.log("input", input)

                                    if(!node.newNode && this.options.onUpdate){
                                        node.level = this.getLevel(node)
                                        this.options.onUpdate(node)
                                    }
                                    this.scrollLeft = this.diagramSvg.parentElement.scrollLeft
                                    this.scrollTop = this.diagramSvg.parentElement.scrollTop
                                    this.build()
                                })
                            }
                            listeners(node)
                        })
                        if (this.buttons)
                            this.buttons.forEach(button => {
                                button.element = document.createElement("button")
                                div.appendChild(button.element)
                                button.element.style.textWrap = "nowrap"
                                button.element.innerHTML = button.text
                                button.element.class = button.class ? button.class : ""
                                if (button.handler) {
                                    console.log("handler")
                                    let tmp = node => {
                                        button.element.addEventListener("click", () => button.handler(node))
                                    }
                                    tmp(node)
                                }
                            })
                        var boxWidth = div.clientWidth + 20
                        var boxHeight = div.clientHeight + 30

                        console.log("boxWidth", boxWidth)
                        console.log("boxHeight", boxHeight)
                        node.width = boxWidth
                        node.height = boxHeight
                        rect.setAttributeNS(null, 'rx', "5px");
                        rect.setAttributeNS(null, 'ry', "5px");
                        rect.setAttribute("y", Math.floor(yValue) + "px");
                        rect.setAttribute("width", Math.floor(boxWidth) + "px");
                        rect.setAttribute("height", Math.floor(boxHeight) + "px");

                        foreignObject.setAttribute("y", Math.floor(yValue) +
                            "px");
                        foreignObject.setAttribute('width', boxWidth + "px");
                        foreignObject.setAttribute('height', boxHeight + "px");
                    }
                }
            }
        }
      
        // xPos
        console.log("Levels",this.outerLevels)
         let betweenNodesSpacing = 7,
            betweenGroupsSpacing = 50,
            levelShift = 0
        console.log("this.outerLevels",this.outerLevels)
        let setNodeXPosition = (node,x)=>{
            node.x = x
            node.rect.setAttribute("x", x + "px")
            node.foreignObject.setAttribute("x", x + "px")
        }
        for(let outerLevelIdx = 0; outerLevelIdx < this.outerLevels.length; outerLevelIdx++) {
            let levels = this.outerLevels[outerLevelIdx]
            let level = levels[0];
            var totalX = levelShift + 10
            // set XPos for longest level and the levels bellow it
            for (let memberIdx = 0; memberIdx < level.length; memberIdx++) {
                let group = level[memberIdx];
                let node
                for (let nodeIdx = 0; nodeIdx < group.length; nodeIdx++) {
                    node = group[nodeIdx];
                    if (!node.children && nodeIdx == 0 && memberIdx == 0) {
                        setNodeXPosition(node, totalX)
                        totalX = node.x + node.width + betweenNodesSpacing
                    } else if (!node.children && nodeIdx == 0) {
                        totalX += betweenGroupsSpacing
                        setNodeXPosition(node, totalX)
                        totalX = node.x + node.width + betweenNodesSpacing
                    } else if (!node.children) {
                        setNodeXPosition(node, totalX)
                        totalX = node.x + node.width + betweenNodesSpacing
                    } else {
                        let largestX = levelShift
                        if (nodeIdx == 0 && memberIdx != 0)
                            totalX += betweenGroupsSpacing
                        let temp = n => {
                            if (n.children) {
                                n.children.forEach(nn => temp(nn))
                                totalX = n.children[0].x + (n.children[n.children.length - 1].x - n.children[0].x) / 2
                                setNodeXPosition(n, totalX)
                                totalX = n.children[n.children.length - 1].x + n.children[n.children.length - 1].width + betweenNodesSpacing
                            }
                            else {
                                if(this.nodeIndexInGroup(levels,n)==0)
                                    totalX = largestX + betweenGroupsSpacing
                                let previousNode = this.getPreviousNode(levels,n)
                                console.log("previousNode",previousNode)
                                if(previousNode && previousNode.children && (previousNode.x+previousNode.width)<=largestX)
                                    totalX = largestX + betweenGroupsSpacing
                                else if(previousNode && previousNode.children)
                                    totalX = previousNode.x + previousNode.width + betweenGroupsSpacing + betweenNodesSpacing
                                setNodeXPosition(n, totalX)
                                totalX += n.width + betweenNodesSpacing
                                largestX = totalX
                            }

                        }
                        node.children.forEach(n => temp(n))
                        totalX = node.children[0].x + (node.children[node.children.length - 1].x - node.children[0].x) / 2
                        setNodeXPosition(node, totalX)
                        totalX = largestX
                        //totalX += node.children[node.children.length - 1].x+node.children[node.children.length - 1].width+betweenNodesSpacing
                    }


                }
            }

            levelShift+=this.getLevelWidth(levels)+100
            console.log("maxLevelWidth",this.getLevelWidth(levels))
            console.log("levelShift",levelShift)
        }
        // Lines
        for(let outerLevelIdx = 0; outerLevelIdx < this.outerLevels.length; outerLevelIdx++) {
            let levels = this.outerLevels[outerLevelIdx]
            for (let levelIdx = 1; levelIdx != levels.length; levelIdx++) {
                let level = levels[levelIdx];
                for (let memberIdx = 0; memberIdx != level.length; memberIdx++) {
                    let group = level[memberIdx];
                    for (let nodeIdx = 0; nodeIdx != group.length; nodeIdx++) {
                        let node = group[nodeIdx]
                        console.log(node);
                        node.line = document.createElementNS(namespace, "line");
                        this.diagramGroup.appendChild(node.line);
                        var line = node.line;
                        line.setAttribute("x1",
                            Math.floor(node.parent.x + node.parent.width / 2) +
                            "px");
                        line.setAttribute("y1",
                            Math.floor(node.parent.y + node.parent.height) + "px");
                        line.setAttribute("x2",
                            node.x + node.width / 2 + "px");
                        line.setAttribute("y2",
                            Math.floor(node.y) + "px");

                        line.setAttribute("marker-end", "url(#arrowHead)");
                    }
                }
            }
        }
        console.log("this.diagramGroup.clientWidth",this.diagramGroup.getBoundingClientRect().width)
        this.diagramSvg.style.width=levelShift//this.diagramGroup.getBoundingClientRect().width+"px"
        this.diagramSvg.style.height=this.diagramGroup.getBoundingClientRect().height+"px"

        this.width = levelShift
        this.height = this.diagramSvg.getClientRects()[0].height
        if(this.scrollLeft){
            this.diagramSvg.parentElement.scrollLeft = this.scrollLeft
            this.diagramSvg.parentElement.scrollTop = this.scrollTop
            delete this.scrollLeft
            delete this.scrollTop
        }
    }
    getLevel(node){
        let level = 0
        let parent = node
        for(let i=0;i<this.maxLevels;i++){
            if(parent.parent){
                level++
                parent = parent.parent
            }
        }
        return level
    }
    getPreviousNode(levels,node){
        let nodeLevel,nodeIndex
        for(let levelIdx=0; levelIdx<levels.length; levelIdx++){
            levels[levelIdx].forEach(g => {

                if(g.find(n=>n.treeStamp==node.treeStamp)){
                    nodeLevel = levels[levelIdx].reduce((all,group)=>[...all,...group],[])
                    nodeIndex = nodeLevel.findIndex(n=>n.treeStamp==node.treeStamp)
                    console.log("nodeLevel",nodeLevel,"nodeIndex",nodeIndex)
                }
            })
        }
        if(nodeIndex==0) return null
        return nodeLevel[nodeIndex-1]
    }
    nodeIndexInGroup(levels,node){
        let nodeIndex
        for(let levelIdx=0; levelIdx<levels.length; levelIdx++){
            levels[levelIdx].forEach(g => {
                let index = g.findIndex(n=>n.treeStamp==node.treeStamp)
                if(index != -1){
                    nodeIndex =index
                }

            })
        }
        return nodeIndex
    }
    getLevelWidth(levels){
        let maxX = 0
        let minX
        for(let levelIdx=0; levelIdx<levels.length; levelIdx++){
            var level = levels[levelIdx]
            var firstGroup = level[0]
            var lastGroup = level[level.length-1]
            let firstNode = firstGroup[0]
            let lastNode = lastGroup[lastGroup.length-1]
            let currentMaxX = lastNode.x + lastNode.width
            let currentMinX = firstNode.x
            if(currentMaxX>maxX){
                maxX = currentMaxX
            }
            if(minX==undefined)
                minX = currentMinX
            if(currentMinX<minX){
                minX = currentMinX
            }
        }
        console.log("minX",minX,"maxX",maxX)
        return maxX-minX
    }
    createParents(){
        var counter = 1
        function createParent(parent,item){
            item.parent = parent
            item.treeStamp = counter
            counter++
            if(item.children)
                item.children.forEach(child=>createParent(item,child))

        }
        this._data.forEach(item=>{
            item.treeStamp = counter
            counter++
            if(item.children)
                item.children.forEach(child=>createParent(item,child))

        })
    }
    makeLevels() {


        this.outerLevels = []
        for (var memberIdx = 0; memberIdx != this._data.length; memberIdx++) {
            var groups = [];
            groups.push([this._data[memberIdx]]);
            var levels = [];
            let cc = true
            while (cc) {
                levels.push(groups);
                var groupsOut = [];
                for (var groupIdx = 0; groupIdx != groups.length; groupIdx++) {
                    var group = groups[groupIdx];

                    for (let memberIdx = 0; memberIdx != group.length; memberIdx++) {
                        var member = group[memberIdx];
                        if (!member.children || !member.children.length)
                            continue;
                        groupsOut.push(member.children);
                    }
                }
                groups = groupsOut;
                if (groups.length == 0)
                    break;
            }
            this.outerLevels.push(levels)
        }
    }
    save(){
        var c = document.createElement("div")
        c.innerHTML = this.element.innerHTML
        c.querySelector("g").style.transform = "scale(1)"
        // c.querySelector("style").remove()
        // var inputsSVG = []
        var parentRect = this.element.querySelector("svg").getBoundingClientRect()
        Array.from(this.element.querySelectorAll("input"))
            .forEach(input=>{
                let rect = input.getBoundingClientRect()
                console.log(rect)
                // inputsSVG.push()
                var text = document.createElementNS("http://www.w3.org/2000/svg", "text");
                text.setAttribute("font-size", "13px")
                text.setAttribute("fill", "white")
                text.setAttribute("x", (rect.x-parentRect.x) + "px");
                text.setAttribute("y", (rect.y-parentRect.y+15) + "px");
                // text.setAttribute("width", rect.width + "px");
                text.innerHTML = input.value
                c.querySelector("g").appendChild(text)
            })
        Array.from(c.querySelectorAll("foreignObject"))
            .forEach(obj=>{
                //console.log(obj)
                obj.remove()
            })
        var svgBlob = new Blob([c.querySelector("svg").parentElement.innerHTML], {type:"image/svg+xml;charset=utf-8"});
        var svgUrl = URL.createObjectURL(svgBlob);
        var downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = "newesttree.svg";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    getTextWidth(text){
        var element = document.createElement("span")
        element.innerHTML = text
        element.style.opacity = "0"
        element.style.position = "static"
        document.body.appendChild(element)
        var width = element.getBoundingClientRect().width
        element.remove()
        return width
    }
    get data2(){
        function copyItem(item){
            var newItem = Object.assign({},item)
            delete newItem.parent
            if(newItem.children)
                newItem.children=newItem.children.map(i=>copyItem(i))
            delete newItem.x
            delete newItem.y
            delete newItem.totalX
            delete newItem.rect
            delete newItem.width
            delete newItem.height
            delete newItem.line
            delete newItem.x0
            delete newItem.x1
            delete newItem.parent
            delete newItem.treeStamp
            return newItem
        }
        return this._data.map(item=>copyItem(item))
    }
    saveState(){
        if (this.movements.length > 20)
            this.movements.shift()
        this.movements.push(JSON.parse(JSON.stringify(this.data2)))
        console.log("this.movements",this.movements)
    }
    get data(){
        function copyItem(item){
            var newItem = Object.assign({},item)
            newItem.inputs = Object.assign([],item.inputs)
            newItem.inputs = newItem.inputs.map(input=>{
                var i = Object.assign({},input)
                delete i.element
                return i
            })
            delete newItem.parent
            if(newItem.children)
                newItem.children=newItem.children.map(i=>copyItem(i))
            delete newItem.x

            delete newItem.y
            delete newItem.newNode
            delete newItem.totalX
            delete newItem.rect
            delete newItem.width
            delete newItem.height
            delete newItem.line
            delete newItem.x0
            delete newItem.x1
            delete newItem.parent
            delete newItem.color
            delete newItem.treeStamp
            delete newItem.foreignObject
            return newItem
        }
        return this._data.map(item=>copyItem(item))
    }
}

export default TreeBuilder
