<template>
  <input type="search" ref="f" @keydown="testKeyUp($event)" @input="formatCurrencyKeyDown($event)">
</template>
<script>
export default {
  props: {
    modelValue: Number,
    max : Number,
    min : Number,
    step : Number
  },
  data(){
    return {
      oldText:"",
      keyCode:null,
      previousValues:[],
      CtrlZClicked:false
    }
  },
  emits: ['update:modelValue'],
  methods:{
    testKeyUp(event){

      if(event.ctrlKey && event.key === 'z' && this.previousValues.length>0) {
        console.log("ctrlKey + Z")
        this.CtrlZClicked = true
        setTimeout(()=>{
          this.CtrlZClicked = false
        },200)
        this.previousValues.pop()
        return this.formatCurrency(this.previousValues[this.previousValues.length-1],null,true)
      }
      this.keyCode = event.keyCode
      console.log(event.keyCode) // 38 up , 40 down

      if(event.keyCode==38)
        this.formatCurrency((parseFloat(this.modelValue)+ (this.step ? parseFloat(this.step) : 1) ).toString(),true)
      else if(event.keyCode==40)
        this.formatCurrency((parseFloat(this.modelValue)- (this.step ? parseFloat(this.step) : 1) ).toString(),true)
    },
    formatCurrencyKeyDown(event){
      console.log("@input")
      if(!this.CtrlZClicked)
      this.formatCurrency(event.target.value)
    },
    formatCurrency(text,goToEnd,dontSaveOld){
      if(typeof(text)=='string' && text.length>1 && text[0]=="0" )
        text = text.substring(1)

      var input_val = text?text:"";
      if (input_val === "") { return this.$emit('update:modelValue',0); }

      // initial caret position
      var caret_pos = this.$refs.f.selectionStart;
      // check for decimal
      if(parseFloat(input_val.replaceAll(",",""))>this.max){
        input_val = this.max
      }
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");

        // split number by decimal point
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        // add commas to left side of number
        left_side = this.formatNumber(left_side);

        // validate right side
        right_side = this.formatNumber(right_side);

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 3);

        // join number by .
        input_val = left_side + "." + right_side;

      }
      else {
        input_val = this.formatNumber(input_val);
      }

      this.$refs.f.value = input_val!=="0"?input_val:"";

      // put caret back in the right position
      this.$refs.f.setSelectionRange(caret_pos + (input_val.substring(0,caret_pos).split(",").length - this.oldText.substring(0,caret_pos).split(",").length) + (input_val.length>=6 && caret_pos<=3 && input_val.substring(0,caret_pos).split(",").length==1 ? 1 : 0) , caret_pos + (input_val.substring(0,caret_pos).split(",").length - this.oldText.substring(0,caret_pos).split(",").length) + (input_val.length>=6 && caret_pos<=3 && input_val.substring(0,caret_pos).split(",").length==1 ? 1 : 0) );

      this.oldText = input_val
      let value = parseFloat(input_val.replaceAll(",",""))
      if(!dontSaveOld && (this.previousValues.length==0 || this.previousValues[this.previousValues.length-1]!=input_val ))
        this.previousValues.push(input_val)
        if(goToEnd)
          this.$refs.f.setSelectionRange(input_val.length ,input_val.length );
      console.log(dontSaveOld,"this.previousValues",this.previousValues)
      this.$emit('update:modelValue',(value?value:0) );
    },
    formatNumber(n) {
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  watch:{
    modelValue(){
      this.formatCurrency(this.modelValue?.toString())
    }
  },
  mounted() {
    console.log("this.modelValue",this.modelValue,this.$refs.f)
    this.formatCurrency(this.modelValue?.toString())
  },
  unmounted() {
    this.previousValues = []
  }

}
</script>
<style></style>
