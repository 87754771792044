<script>

import PageHeader from "@/components/page-header";
import Layouts from "../../layouts/main";

export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    Layouts,
    PageHeader,
  },
  data() {
    return {
      accounts: [],
      levelTwoAccounts: [],
      levelThreeAccounts: [],
      matchedAccounts:[],
    };
  },
  methods: {
    getLevelTwo() {
      this.http
          .post("subledgers/get-level-two-setup", {
            subledger_id: 4
          })
          .then((res) => {
            if (res.status) {
              this.levelTwoAccounts = res.data;
            }
          });
    },
    searchAccount(account,search) {
      console.log("account",account,"search",search)

      this.http
          .post("subledgers/accounts-setup/search", {
            search,
            level:account.level,
            type:account.type,
          })
          .then((res) => {
            if (res.status) {
              this.matchedAccounts = res.data
            }
          });
    },

    editLevelTwo(account) {
      console.log("account",account)
      this.http.post("subledgers/types/setup/edit-level-two", {
        id:account.id,
        level_two_id:account.search.id
      }).then((res) => {
        if (res.status) {
          this.levelTwoAccounts = [];
          this.getLevelTwo();
        }
      });
    },
    editLevelThree(account) {
      console.log("account",account)
      this.http
          .post("subledgers/types/setup/edit-level-three", {
            id:account.sub_accounts[0].sas_id,
            level_three_id:account.search.id
          })
          .then((res) => {
            if (res.status) {
              console.log("done");
              account.search = "";
              this.getLevelThree();
            }
          });
    },
    getLevelThree() {
      this.http
          .post(
              "subledgers/accounts-setup/pagination",
              {
                subledger_id: 4
              }
          )
          .then((res) => {
            if (res.status) {
              this.levelThreeAccounts = res.data.filter(account=>account.type);
            }
          });
    },
    showLabel(one) {
      return this.$i18n.locale == "ar" ? one.code + '-' + one.name : one.code + '-' + one.name_en; // Display the group label in AutoComplete
    },
  },
  computed: {

  },
  mounted() {
    this.getLevelTwo();
    this.getLevelThree();
  },
};
</script>

<template>
  <Layouts>
    <PageHeader :title="$t('accounts_setup.setup') + '  -  '+ $t('menu.menuitems.subledgers_setup.subItems.assets_subledger')" />
    <b-tabs
        content-class="p-4"
        class="pt-2"
        nav-wrapper-class="nav-item"
        nav-class="nav-tabs-custom"
    >
      <b-tab active>
        <template v-slot:title>
          <div type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block"
            >{{ $t("accounts_setup.level_two") }}
            </span>
          </div>
        </template>

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table
                      class="table table-centered table-nowrap table-striped table-hover align-middle"
                  >
                    <thead>
                    <tr
                        class="text-light"
                        style="background-color: #2a3042 !important"
                    >
                      <th scope="col">#</th>
                      <!-- <th scope="col">{{ $t("fixed_assets.module") }}</th> -->
                      <th scope="col">{{ $t("fixed_assets.module_name") }}</th>
                      <th scope="col">
                        {{ $t("popups.code") }}
                      </th>
                      <th scope="col">
                        {{ $t("fixed_assets.account_name") }}
                      </th>
                      <th scope="col">
                        {{ $t("fixed_assets.operations") }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(account, index) in levelTwoAccounts" :key="account">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{
                          $i18n.locale == "ar" ? account.name : account.name_en
                        }}
                      </td>
                      <td>{{ account?.code }}</td>
                      <td>
                        {{
                          $i18n.locale == "ar"
                              ? account?.l2_name
                              : account?.l2_name_en
                        }}
                      </td>
                      <td>
                        <div class="d-flex">
                          <AutoComplete
                              v-model="account.search"
                              :suggestions="matchedAccounts"
                              @complete="searchAccount(account,$event.query)"
                              :optionLabel="showLabel"
                              style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                          />
                          <button
                              style="margin-inline-start: 8px"
                              @click="editLevelTwo(account)"
                              type="button"
                              class="btn btn-primary"
                          >
                            {{ $t("popups.edit") }}
                          </button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end  table -->
                <!-- Button trigger modal -->

                <!--   Apps  Pagination     -->
                <ul
                    class="pagination pagination-rounded justify-content-center mb-2"
                >
                  <li class="page-item" :class="{ disabled: page == 1 }">
                    <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="getLevelTwo(page - 1)"
                        aria-label="Previous"
                    >
                      <i class="mdi mdi-chevron-left"></i>
                    </a>
                  </li>
                  <li
                      class="page-item"
                      :class="{ active: p == page }"
                      v-for="p in tot_pages"
                      :key="p"
                  >
                    <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="getLevelTwo(p)"
                    >{{ p }}</a
                    >
                  </li>

                  <li class="page-item" :class="{ disabled: page == tot_pages }">
                    <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="getLevelTwo(page + 1)"
                        aria-label="Next"
                    >
                      <i class="mdi mdi-chevron-right"></i>
                    </a>
                  </li>
                </ul>
                <!--end-->
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab >
        <template v-slot:title>
          <div type="button">
            <span class="d-inline-block d-sm-none">
              <i class="fas fa-home"></i>
            </span>
            <span class="d-none d-sm-inline-block"
            >{{ $t("accounts_setup.level_three") }}
            </span>
          </div>
        </template>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table
                      class="table table-centered table-nowrap table-striped table-hover align-middle"
                  >
                    <thead>
                    <tr
                        class="text-light"
                        style="background-color: #2a3042 !important"
                    >
                      <th scope="col">#</th>
                      <!-- <th scope="col">{{ $t("fixed_assets.module") }}</th> -->
                      <th scope="col">{{ $t("fixed_assets.module_name") }}</th>
                      <th scope="col">
                        {{ $t("popups.code") }}
                      </th>
                      <th scope="col">
                        {{ $t("fixed_assets.account_name") }}
                      </th>
                      <th scope="col">
                        {{ $t("fixed_assets.operations") }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(account,index) in levelThreeAccounts" :key="account">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{
                          $i18n.locale == "ar" ? account?.account_name : account?.account_name_en
                        }}
                      </td>
                      <td>{{ account?.sub_accounts[0]?.code }}</td>
                      <td>
                        {{
                          $i18n.locale == "ar"
                              ? account?.sub_accounts[0]?.l3_name
                              : account?.sub_accounts[0]?.l3_name_en
                        }}
                      </td>
                      <td>
                        <div class="d-flex">
                          <AutoComplete
                              v-model="account.search"
                              :suggestions="matchedAccounts"
                              @complete="searchAccount(account,$event.query)"
                              :optionLabel="showLabel"
                              style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                          />
                          <button
                              style="margin-inline-start: 8px"
                              @click="editLevelThree(account)"
                              type="button"
                              class="btn btn-primary"
                          >
                            {{ $t("popups.edit") }}
                          </button>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end  table -->
                <!-- Button trigger modal -->

                <!--   Apps  Pagination     -->
                <ul
                    class="pagination pagination-rounded justify-content-center mb-2"
                >
                  <li class="page-item" :class="{ disabled: page == 1 }">
                    <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="getLevelThree(page - 1)"
                        aria-label="Previous"
                    >
                      <i class="mdi mdi-chevron-left"></i>
                    </a>
                  </li>
                  <li
                      class="page-item"
                      :class="{ active: p == page }"
                      v-for="p in tot_pages"
                      :key="p"
                  >
                    <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="getLevelThree(p)"
                    >{{ p }}</a
                    >
                  </li>

                  <li class="page-item" :class="{ disabled: page == tot_pages }">
                    <a
                        class="page-link"
                        href="javascript: void(0);"
                        @click="getLevelThree(page + 1)"
                        aria-label="Next"
                    >
                      <i class="mdi mdi-chevron-right"></i>
                    </a>
                  </li>
                </ul>
                <!--end-->
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </Layouts>

</template>
