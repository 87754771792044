import EquitySubledger from "@/router/views/Subledgers/EquitySubledger";
import SalesSubledger from "@/router/views/Subledgers/SalesSubledger";
import InventorySubledger from "@/router/views/Subledgers/InventorySubledger";
import PurchasesSubledger from "@/router/views/Subledgers/PurchasesSubledger";
import CustomersSubledger from "@/router/views/Subledgers/CustomersSubledger";
import SuppliersSubledger from "@/router/views/Subledgers/SuppliersSubledger";
import EmployeesSubledger from "@/router/views/Subledgers/EmployeesSubledger";
import FixedAssetsSubledger from "@/router/views/Subledgers/FixedAssetsSubledger";
import CashAndBanksSubledger from "@/router/views/Subledgers/CashAndBanksSubledger";
import ChartOfAccounts from "@/router/views/Subledgers/ChartOfAccounts";
// import { start } from "@popperjs/core";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/allowances",
    name: "allowances",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Allowances/AllowancesPage"),
  },

  {
    path: "/browsers",
    name: "browsers",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Browsers/BrowsersPage"),
  },

  // {
  //   path: "/budget",
  //   name: "budget",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Budget/BudgetPage"),
  // },
  {
    path: "/purchases_setup",
    name: "purchases_setup",
    meta: {
      authRequired: true,
      auth_users: ["crm"],

    },
    component: () => import("./views/Purchases/PurchasesSetupPage/index.vue"),
  },
  {
    path: "/purchases_agents_management",
    name: "purchases_agents_management",
    meta: {
      authRequired: true,
      auth_users: ["crm"],

    },
    component: () =>
        import("./views/Purchases/PurchasesAgents/PurchasesAgentsPage.vue"),
  },
  {
    path: "/sales_agents_management",
    name: "sales_agents_management",
    meta: {
      authRequired: true,
      auth_users: ["crm"],

    },
    component: () =>
        import("./views/Sales/SalesAgents/SalesAgentsPage"),
  },
  {
    path: "/purchases_agents_profile/:id",
    name: "purchases_agents_profile/:id",
    meta: {
      authRequired: true,
      auth_users: ["am"],

    },
    component: () =>
        import(
            "./views/Purchases/PurchasesAgents/PurchasesAgentsProfile/index.vue"
            ),
  },
  {
    path: "/sales_agents_profile/:id",
    name: "sales_agents_profile/:id",
    meta: {
      authRequired: true,
      auth_users: ["am"],

    },
    component: () =>
        import(
            "./views/Sales/SalesAgents/SalesAgentsProfile/index.vue"
            ),
  },
  {
    path: "/purchases",
    name: "purchases",
    meta: {
      authRequired: true,
      auth_users: ["am"],

    },
    component: () =>
        import("./views/Purchases/PurchasesPage/PurchasesPage.vue"),
  },
  {
    path: "/sales",
    name: "sales",
    meta: {
      authRequired: true,
      auth_users: ["am"],

    },
    component: () =>
        import("./views/Sales/SalesPage/SalesPage.vue"),
  },
  {
    path: "/purchases_reports",
    name: "purchases_reports",
    meta: {
      authRequired: true,
      auth_users: ["crm"],

    },
    component: () => import("./views/Purchases/PurchasesReportsPage"),
  },
  {
    path: "/purchases_details/:id",
    name: "purchases_details",
    meta: {
      authRequired: true,
      auth_users: ["crm"],

    },
    component: () => import("./views/Purchases/purchasesDetails/index.vue"),
  },
  {
    path: "/purchases_account_setup",
    name: "purchases_account_setup",
    meta: {
      authRequired: true,
      auth_users: ["crm"],

    },
    component: () => import("./views/Purchases/AccountsSetup/index.vue"),
  },
  {
    path: "/supplier-report",
    name: "supplier-report",
    meta: {
      authRequired: true,
      auth_users: ["crm"],

    },
    component: () =>
        import("./views/Purchases/PurchasesAgents/ReportsPage/index.vue"),
  },

  {
    path: "/cheques",
    name: "cheques",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Cheques/ChequesPage"),
  },
  {
    path: "/accounts",
    name: "accounts",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/AccountingPage/AccountsPage/index.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ReportsPage/index.vue"),
  },
  {
    path: "/accounts_setup",
    name: "accounts_setup",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/AccountsSetup/setupPage.vue"),
  },


  {
    path: "/payment_made",
    name: "payment_made",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Purchases/PaymentMade/index.vue"),
  },
  {
    path: "/sale_payment_made",
    name: "sale_payment_made",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Sales/PaymentMade/index.vue"),
  },
  {
    path: "/customer_report",
    name: "customer_report",
    meta: {
      authRequired: true,
    },
    component: () =>
        import("./views/Sales/SalesAgents/ReportsPage"),
  },
  // {
  //   path: "/emp_file_types",
  //   name: "emp_file_types",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/Masters/DocumentTypes/index.vue"),
  // },

  {
    path: "/emp_files",
    name: "emp_files",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/EmpFiles/EmpFilesPage"),
  },

  {
    path: "/emp-job-titles",
    name: "emp-job-titles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/EmpJobTitles/EmpJobTitlesPage"),
  },

  {
    path: "/emp_sectors",
    name: "emp_sectors",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/EmpSectors/EmpSectorsPage"),
  },
  // hr start
  {
    path: "/departments",
    name: "departments",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/DepartmentsPage.vue"),
  },
  {
    path: "/departments/profile/:id",
    name: "departments_profile",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/DepartmentsProfilePage.vue"),
  },
  {
    path: "/hr_personal_records",
    name: "hr_personal_records",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/PersonalRecordsPage.vue"),
  },
  {
    path: "/hr_personal_records/empolyee_profile/:id",
    name: "hr_personal_records_empolyee_profile",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/EmployeeProfile.vue"),
  },
  {
    path: "/attendance",
    name: "attendance",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/AttendancePage.vue"),
  },
  {
    path: "/holidays",
    name: "holidays",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/HoliDaysPage.vue"),
  },
  {
    path: "/leaves",
    name: "leaves",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/LeavesPage.vue"),
  },
  {
    path: "/performance_management",
    name: "performance_management",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/PerformanceManagementPage.vue"),
  },
  {
    path: "/performance_management/profile/:id",
    name: "performance_management_profile",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/PerformanceProfileManagementPage.vue"),
  },
  {
    path: "/transfares",
    name: "transfares",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/TransfaresPage.vue"),
  },
  {
    path: "/complains",
    name: "complains",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/ComplainsPage.vue"),
  },
  {
    path: "/hr_reports",
    name: "hr_reports",
    meta: {
      authRequired: true,
    },
    component: import("./views/Hr/HrReportsPage.vue"),
  },
  {
    path: "/hr_attendance",
    name: "hr-attendance",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/HrAttendance/HrAttendancePage.vue"),
  },

  {
    path: "/hr_employees",
    name: "hr-employees",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/HrEmployees/HrEmployeesPage.vue"),
  },

  {
    path: "/hr_salary_items",
    name: "hr-salary-items",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/HrSalaryItems/HrSalaryItemsPage.vue"),
  },

  {
    path: "/hr_tax_levels",
    name: "hr-tax-levels",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/HrTaxLevels/HrTaxLevelsPage.vue"),
  },
  {
    path: "/head_approve_overtime",
    name: "head-approve-overtime",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/HeadApproveOvertimePage.vue"),
  },
  {
    path: "/hr_approve_overtime",
    name: "hr-approve-overtime",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/HrApproveOvertimePage.vue"),
  },

  {
    path: "/hr_approve_award",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/HrAwards/HrApproveAwards.vue"),
  },
  {
    path: "/hr_award_types",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/HrAwards/HrAwardTypes.vue"),
  },
    {
      path: "/payroll_management",
      name: "payroll_management",
      meta: {
        authRequired: true,
      },
      component: () => import("./views/Payroll/index.vue"),
    },
  // {
  //     path: "/payroll_management",
  //     meta: {
  //       authRequired: true,
  //     },
  //     // component: () => import("./views/Hr/PayrollPage.vue"),
  //     // component: () => import("./views/Hr/Payroll/PayrollManagement/index.vue"),
  //     component: () => import("./views/Hr/Payroll/index.vue"),
  // },
  {
    path: "/hr_setup",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Hr/AccountsSetup/index.vue"),
  },
  // hr end
  // human recource
  // {
  //   path: "/emps",
  //   name: "emps",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/Emps/index.vue"),
  // },
  // {
  //   path: "/emps_setup",
  //   name: "emps_setup",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/AccountsSetup/index.vue"),
  // },
  // {
  //   path: "/emps/profile/:id",
  //   name: "profile",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/Emps/Profile/index.vue"),
  // },
  // {
  //   path: "/emps_reports",
  //   name: "emps_reports",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/ReportsPage/index.vue"),
  // },
  // {
  //   path: "/masters",
  //   name: "masters",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/Masters/index.vue"),
  // },
  {
    path: "/expenses-sectors",
    name: "expenses-sectors",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ExpensesSectors/ExpensesSectorsPage"),
  },

  {
    path: "/issue-types",
    name: "issue-types",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/IssueTypes/IssueTypesPage"),
  },

  {
    path: "/level_one_chart_of_accounts",
    name: "level_one_chart_of_accounts",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/LevelOneChartOfAccounts/LevelOneChartOfAccountsPage"),
  },
  {
    path: "/journal_entries",
    name: "journal_entries",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/ManualGournals/JournalEntries/index.vue"),
  },
  {
    path: "/journal_entries/:id",
    name: "journal_entry",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/ManualGournals/JournalEntry/index.vue"),
  },
  {
    path: "/add_journal",
    name: "add_journal",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/ManualGournals/AddJournal/index.vue"),
  },
  {
    path: "/level_three_chart_of_accounts_copy-1",
    name: "level_three_chart_of_accounts_copy-1",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/LevelThreeChartOfAccountsCopy1/LevelThreeChartOfAccountsCopy1Page"
      ),
  },

  {
    path: "/level_two_chart_of_accounts",
    name: "level_two_chart_of_accounts",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/LevelTwoChartOfAccounts/LevelTwoChartOfAccountsPage"),
  },

  {
    path: "/main-chart-of-accounts-types",
    name: "main-chart-of-accounts-types",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/MainChartOfAccountsTypes/MainChartOfAccountsTypesPage"),
  },

  {
    path: "/monthly-salary-details",
    name: "monthly-salary-details",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/MonthlySalaryDetails/MonthlySalaryDetailsPage"),
  },

  {
    path: "/opening-balances",
    name: "opening-balances",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/OpeningBalances/OpeningBalancesPage"),
  },

  {
    path: "/accounting-peroids",
    name: "accounting periods",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPeroids/AccountingPeroidsPage.vue"),
  },

  {
    path: "/roles",
    name: "roles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Roles/RolesPage"),
  },

  // {
  //   path: "/salary-deduction",
  //   name: "salary-deduction",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/SalaryDeduction/index.vue"),
  // },

  {
    path: "/salary_item_degrees",
    name: "salary_item_degrees",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SalaryItemDegrees/SalaryItemDegreesPage"),
  },
  {
    path: "/salary-items",
    name: "salary-items",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SalaryItems/SalaryItemsPage"),
  },
  // {
  //   path: "/salary-items-categories",
  //   name: "salary-items-categories",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () =>
  //     import("./views/SalaryItems/SalaryItemsCategories/index.vue"),
  // },
  {
    path: "/salary_items_branches",
    name: "salary_items_branches",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/SalaryItemsBranches/SalaryItemsBranchesPage"),
  },
  {
    path: "/add-branch-item/:id",
    name: "add-branch-item",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/SalaryItems/AddItem/index.vue"),
  },
  {
    path: "/sessions",
    name: "sessions",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Sessions/SessionsPage"),
  },

  {
    path: "/transactions",
    name: "transactions",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Transactions/TransactionsPage"),
  },

  {
    path: "/user-roles",
    name: "user-roles",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/UserRoles/UserRolesPage"),
  },

  {
    path: "/users",
    name: "users",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/UsersPage"),
  },

  {
    path: "/monthly_employee_salary",
    name: "monthly_employee_salary",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/Monthly Employees Salary/MonthlySalary.vue"),
  },
  {
    path: "/employee_details/:id",
    name: "employee_details",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Employ Details/EmployeeDetails"),
  },

  // {
  //   path: "/payroll",
  //   name: "payroll",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/Payroll/index.vue"),
  // },
  // {
  //   path: "/payroll_managment",
  //   name: "payroll_managment",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/Payroll/PayrollManagement/index.vue"),
  // },
  // {
  //   path: "/master_payroll",
  //   name: "master_payroll",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/Payroll/MasterPayroll/index.vue"),
  // },
  {
    path: "/payroll_report",
    name: "payroll_report",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/Payroll Managment/Payroll Report/PayrollReport.vue"),
  },
  // {
  //   path: "/emps_report",
  //   name: "emps_report",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import("./views/Hr/Employee Report/EmployeeReport.vue"),
  // },
  {
    path: "/accounting_transaction",
    name: "accounting_transaction",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/AccountingTransaction/AccountingTransaction.vue"
      ),
  },
  {
    path: "/revenues",
    name: "revenues",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/AccountingRevenues/AccountingRevenues.vue"
      ),
  },
  {
    path: "/cash_and_banks",
    name: "cash_and_banks",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/AccountingPage/CashAndBanks/index.vue"),
  },
  {
    path: "/balance_sheet",
    name: "balance_sheet",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/Balance Sheet/BalanceSheet.vue"),
  },
  {
    path: "/trial_balance",
    name: "trial_balance",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/AccountingPage/Trial Balance/TrialBalance.vue"),
  },
  {
    path: "/income-statement",
    name: "income_statement",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/IncomeStatement/IncomeStatement.vue"),
  },
  {
    path: "/general_ledger",
    name: "general_ledger",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/GeneralLedger/MonthlyGeneral.vue"),
  },
  {
    path: "/debtors",
    name: "debtors",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/DebtorsPage/DebtorsPage.vue"),
  },
  {
    path: "/creditors",
    name: "creditors",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/CreditorsPage/CreditorsPage.vue"),
  },
  {
    path: "/add_cash",
    name: "add_cash",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/CashAndBanks/CashAccounts/AddAccount/index.vue"
      ),
  },
  {
    path: "/edit_cash/:id",
    name: "edit_cash/:id",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/CashAndBanks/CashAccounts/EditAccount/index.vue"
      ),
  },
  {
    path: "/add_bank",
    name: "add_bank",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/CashAndBanks/BankAccounts/AddAccount/index.vue"
      ),
  },
  {
    path: "/edit_bank/:id",
    name: "edit_bank/:id",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/CashAndBanks/BankAccounts/EditAccount/index.vue"
      ),
  },
  {
    path: "/general_ledger",
    name: "general_ledger",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/GeneralLedger/MonthlyGeneral.vue"),
  },
  {
    path: "/general_ledger/report/:id/:from/:to",
    name: "general_ledger_report",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/AccountingReport/GeneralLedger/GeneralLedger.vue"
      ),
  },
  // {
  //   path: "/income_statement",
  //   name: "income_statement",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () =>
  //     import("./views/AccountingPage/IncomeStatement/IncomeStatement.vue"),
  // },
  {
    path: "/financial_statments",
    name: "financial_statments",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/Financial Statement/FinancialStatement.vue"),
  },
  {
    path: "/cash-flow-statement",
    name: "cash-flow-statement",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Cashflow Statement/CashflowStatement"),
  },
  {
    path: "/bills_payable",
    name: "bills_payable",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/BillsPayable/BillsPayable.vue"),
  },
  {
    path: "/bills_receivable",
    name: "bills_receivable",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/BillsReceivable/BillsReceivable.vue"),
  },
  {
    path: "/trial_balance",
    name: "trial_balance",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Trial Balance/TrialBalance"),
  },
  {
    path: "/opening_balances",
    name: "opening_balances",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/OpeningBalances/OpeningBalancesPage"),
  },
  {
    path: "/asset_valuation",
    name: "asset_valuation",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Asset Valuation/AssetValuation"),
  },
  {
    path: "/month_general",
    name: "month_general",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/GeneralLedger/MonthlyGeneral.vue"),
  },
  {
    path: "/income_expenditure",
    name: "income_expenditure",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/IncomeExpenditure/IncomeExpenditure.vue"),
  },
  {
    path: "/debtors",
    name: "debtors",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/DebtorsPage/DebtorsPage.vue"),
  },
  {
    path: "/fixed-assets",
    name: "fixed-assets",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/FixedAssets/index.vue"),
  },
  {
    path: "/disposed-assets",
    name: "disposed-assets",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/FixedAssets/DisposedAssetsPage/index.vue"),
  },
  {
    path: "/fixed-assets-reports",
    name: "fixed-assets-reports",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/FixedAssets/ReportsPage/index.vue"),
  },
  {
    path: "/creditors",
    name: "creditors",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/CreditorsPage/CreditorsPage.vue"),
  },
  {
    path: "/bill_age_analysis_report",
    name: "bill_age_analysis_report",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/BillAgeAnalysisReport/BillAgeAnalysisReport.vue"
      ),
  },
  // {
  //   path: "/income-statement",
  //   name: "income-statement",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () =>
  //     import("./views/AccountingPage/IncomeStatement/IncomeStatement.vue"),
  // },
  {
    path: "/assets_depreciation",
    name: "assets_depreciation",
    meta: {
      authRequired: true,
    },
    component: () =>
      import(
        "./views/AccountingPage/AssetsDepreciation/AssetsDepreciation.vue"
      ),
  },
  {
    path: "/balance_sheet",
    name: "balance_sheet",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/Balance Sheet/BalanceSheet.vue"),
  },
  {
    path: "/budget",
    name: "budget",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Budget/index.vue"),
  },
  {
    path: "/budget_vs_actual_report",
    name: "budget_vs_actual_report",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Budget/ReportsPage/index.vue"),
  },
  // {
  //   path: "/emps_attendes",
  //   name: "emps_attendes",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () =>
  //     import("./views/Hr/Employees  Attendes/EmplyeesAttendes.vue"),
  // },
  // {
  //   path: "/emps_attends_detials/:id",
  //   name: "emps_attends_detials",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () =>
  //     import("./views/Hr/Employee Attends Details/EmployeeAttendsdetails.vue"),
  // },
  {
    path: "/transaction_manger",
    name: "transaction_manger",
    meta: {
      authRequired: true,
    },
    component: () =>
      import("./views/AccountingPage/Accounting Manger/TransactionManger.vue"),
  },
  // {
  //   path: "/emp_work_day",
  //   name: "emp_work_day",
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () =>
  //     import("./views/Hr/Employees Workday/EmployeesWorkday.vue"),
  // },
  {
    path: '/categories',
    name: 'categories',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Categories/CategoriesPage.vue"),
  },
  {
    path: '/categories/:id/products',
    name: 'categories-products',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Categories/Products/Index.vue"),
  },
  {
    path: '/inventories',
    name: 'inventories',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Inventories/InventoriesPage.vue"),
  },
  {
    path: '/product-report',
    name: 'product_report',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Inventories/ProductReport.vue"),
  },
  {
    path: '/inventories-profile/:id',
    name: 'inventories_profile',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Inventories/profile/index.vue"),
  },

  {
    path: "/chart_of_accounts",
    name: "chart_of_accounts",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: ChartOfAccounts,
  },
  {
    path: "/cash_and_banks_subledger",
    name: "cash_and_banks_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: CashAndBanksSubledger,
  },
  {
    path: "/assets_subledger",
    name: "assets_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: FixedAssetsSubledger,
  },
  {
    path: "/emps_subledger",
    name: "emps_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: EmployeesSubledger,
  },
  {
    path: "/suppliers_subledger",
    name: "suppliers_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["am"],
    },
    component: SuppliersSubledger,
  },
  {
    path: "/customers_subledger",
    name: "customers_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["am"],
    },
    component: CustomersSubledger,
  },
  {
    path: "/purchases_subledger",
    name: "purchases_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["am"],
    },
    component: PurchasesSubledger,
  },
  {
    path: "/inventory_subledger",
    name: "inventory_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: InventorySubledger,
  },
  {
    path: "/sales_subledger",
    name: "sales_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: SalesSubledger,
  },
  {
    path: "/equity_subledger",
    name: "equity_subledger",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: EquitySubledger,
  },
  {
    path: "/currencies",
    name: "currencies",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () => import("./views/AccountingPage/CurrenciesPage/index.vue"),
  },
  {
    path: "/currencies/:id",
    name: "/currencies/:id",
    meta: {
      authRequired: true,
      auth_roles: ["admin"],
    },
    component: () =>
        import("./views/AccountingPage/CurrenciesPage/Currency/index.vue"),
  },
];
