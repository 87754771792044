export const menuItems = [
  {
    id: 1,
    label: "menu.menuitems.organization.subItems.users",
    icon: "bx-user",
    isAdmin: true,
    subItems: [
      {
        label: "menu.menuitems.organization.subItems.users",
        link: "/users",
        parentId: 1,
        auth_users: ["crm"],
      },

    ],
  },
   {
    id: 20,
    label: "menu.menuitems.hr.text",
    icon: "bx-user-pin",
    subItems: [
      {
        label: "menu.menuitems.hr.subItems.departments",
        link: "/departments",
        parentId: 20,
        auth_users: ["hm" , "am"],
      },
      {
        label: "menu.menuitems.hr.subItems.hr_personal_records",
        link: "/hr_personal_records",
        parentId: 20,
        auth_users: ["hm" , "am"],
      },
      {
        label: "menu.menuitems.hr.subItems.tax_levels",
        link: "/hr_tax_levels",
        parentId: 20,
        auth_users: ["hm" , "am"],
      },
      {
        label: "menu.menuitems.hr.subItems.holidays",
        link: "/holidays",
        parentId: 20,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.leaves",
        link: "/leaves",
        parentId: 20,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.performance_management",
        link: "/performance_management",
        parentId: 20,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.transfares",
        link: "/transfares",
        parentId: 20,
        auth_users: ["hm" , "am"],
      },
      {
        label: "menu.menuitems.hr.subItems.complains",
        link: "/complains",
        parentId: 20,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.salary_items",
        link: "/hr_salary_items",
        parentId: 20,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.hr_approve_overtime",
        link: "/hr_approve_overtime",
        parentId: 20,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.approve_overtime",
        link: "/head_approve_overtime",
        parentId: 20,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.award_approve",
        link: "/hr_approve_award",
        parentId: 20,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.award_type",
        link: "/hr_award_types",
        parentId: 20,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.payroll",
        link: "/payroll_management",
        parentId: 20,
        auth_users: ["hm"],
      },
      {
        label: "menu.menuitems.hr.subItems.setup",
        link: "/hr_setup",
        parentId: 20,
        auth_users: ["hm"],
      },
    ],
  },
  {
    id: 9,
    label: "menu.menuitems.inventories.text",
    icon: "bx-user",
    isAdmin: true,
    subItems: [
      {
        label: "menu.menuitems.inventories.subItems.categories",
        link: "/categories",
        parentId: 9,
        auth_users: ["crm"],

      },
      {
        label: "menu.menuitems.inventories.subItems.inventories",
        link: "/inventories",
        parentId: 9,
        auth_users: ["crm"],

      },
      {
        label: "menu.menuitems.inventories.subItems.product_report",
        link: "/product-report",
        parentId: 9,
        auth_users: ["crm"],

      },
    ],
  },
  {
    id: 10,
    label: "menu.menuitems.purchases.text",
    icon: "bx-cart",
    isAdmin: true,
    auth_users: ["crm"],

    subItems: [
      {
        label: "menu.menuitems.purchases.subItems.purchases_agents_management",
        link: "/purchases_agents_management",
        parentId: 10,
        auth_users: ["crm"],

      },
      {
        label: "menu.menuitems.purchases.subItems.purchases_logs",
        link: "/purchases",
        parentId: 10,
        auth_users: ["crm"],

      },
      {
        label: "menu.menuitems.payments.sub.payment_made",
        link: "/payment_made",
        parentId: 3,
        auth_users: ["crm"],
      },
      {
        label: "menu.menuitems.purchases.subItems.purchases_reports",
        link: "/supplier-report",
        parentId: 10,
        auth_users: ["crm"],

      }
    ],
  },
  {
    id: 12,
    label: "menu.menuitems.sales.text",
    icon: "bx-cart",
    isAdmin: true,
    auth_users: ["crm"],

    subItems: [
      {
        label: "menu.menuitems.sales.subItems.sales_agents_management",
        link: "/sales_agents_management",
        parentId: 12,
        auth_users: ["crm"],

      },
      {
        label: "menu.menuitems.sales.subItems.sales_logs",
        link: "/sales",
        parentId: 12,
        auth_users: ["crm"],

      },
      {
        label: "menu.menuitems.payments.sub.payment_made",
        link: "/sale_payment_made",
        parentId: 12,
        auth_users: ["crm"],
      },
      {
        label: "menu.menuitems.sales.subItems.sales_reports",
        link: "/customer_report",
        parentId: 12,
        auth_users: ["crm"],
      }
    ],
  },

  {
    id: 7,
    label: "menu.menuitems.accounting.subItems.fixed-assets",
    icon: "bx bx-fridge",
    subItems: [
      {
        label: "menu.menuitems.accounting.subItems.fixed-assets",
        link: "/fixed-assets",
        parentId: 7,
        auth_users: ["am"],
      },
      {
        label: "fixed_assets.disposed",
        link: "/disposed-assets",
        parentId: 7,
        auth_users: ["am"],
      },
      {
        label: "fixed_assets.reports",
        link: "/fixed-assets-reports",
        parentId: 7,
        auth_users: ["am"],
      },
    ],
  },
  {
    id: 8,
    label: "menu.menuitems.accounting.text",
    icon: "bx bx-fridge",
    isAdmin: true,
    subItems: [
      {
        label: "menu.menuitems.accounting.subItems.journal_entries",
        link: "/journal_entries",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.cash-banks",
        link: "/cash_and_banks",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.accounting-periods",
        link: "/accounting-peroids",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.budget",
        link: "/budget",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.currencies",
        link: "/currencies",
        parentId: 19,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.balance-sheet",
        link: "/balance_sheet",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.trial-balance",
        link: "/trial_balance",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.income-statement",
        link: "/income-statement",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label:"menu.menuitems.accounting.subItems.general-ledger",
        link:"/general_ledger",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.debtors",
        link: "/debtors",
        parentId: 8,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.accounting.subItems.creditors",
        link: "/creditors",
        parentId: 8,
        auth_users: ["am"],
      },
    ],
  },
  {
    id: 11,
    label: "menu.menuitems.subledgers_setup.text",
    icon: "bx-money",
    isAdmin: true,
    auth_users: ["am"],
    subItems: [
      {
        label: "menu.menuitems.subledgers_setup.subItems.chart_of_accounts",
        link: "/chart_of_accounts",
        parentId: 1,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.subledgers_setup.subItems.cash_and_banks_subledger",
        link: "/cash_and_banks_subledger",
        parentId: 1,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.subledgers_setup.subItems.assets_subledger",
        link: "/assets_subledger",
        parentId: 1,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.subledgers_setup.subItems.emps_subledger",
        link: "/emps_subledger",
        parentId: 1,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.subledgers_setup.subItems.suppliers_subledger",
        link: "/suppliers_subledger",
        parentId: 1,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.subledgers_setup.subItems.customers_subledger",
        link: "/customers_subledger",
        parentId: 1,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.subledgers_setup.subItems.purchases_subledger",
        link: "/purchases_subledger",
        parentId: 1,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.subledgers_setup.subItems.inventory_subledger",
        link: "/inventory_subledger",
        parentId: 1,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.subledgers_setup.subItems.sales_subledger",
        link: "/sales_subledger",
        parentId: 1,
        auth_users: ["am"],
      },
      {
        label: "menu.menuitems.subledgers_setup.subItems.equity_subledger",
        link: "/equity_subledger",
        parentId: 1,
        auth_users: ["am"],
      }
    ],
  },
  {
    id: 5,
    label: "menu.menuitems.reports.text",
    icon: " bx bx-list-check ",
    link: "/reports",
  },

];
